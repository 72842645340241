<template>
    <div class="delivery-wrapper">
        <OrderDelivery :allowed-delivery-dates="allowedDeliveryDates" is-calendar-and-delivery-times-shown>
            <template #btn-section="{ deliveryData }">
                <div class="btn-section__individual">
                    <div class="order-kit__section">
                        <CRButton
                            pattern="outline"
                            @click="$router.push({ name: 'individualOrderKit' })"
                            class="button"
                        >
                            <template v-slot:icon>
                                <ArrowSvg class="arrow-icon" />
                            </template>
                            {{ $t('buttons.goBack') }}
                        </CRButton>
                    </div>
                    <div class="order-kit__section footer_button">
                        <CRButton @click="toBuyerInfoPage(deliveryData)" class="button">
                            {{ $t('common.buyerInfo') }}
                        </CRButton>
                    </div>
                </div>
            </template>
        </OrderDelivery>
    </div>
</template>

<script>
    import OrderDelivery from '@/components/Order/OrderDelivery.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import ArrowSvg from '@/assets/icons/arrows/arrow-down.svg?inline';
    import { CalendarDaysApi } from '@/api';
    import { mapActions } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';

    export default {
        name: 'Delivery',
        components: { OrderDelivery, CRButton, ArrowSvg },
        setup() {
            return { v$: useVuelidate() };
        },
        data() {
            return {
                allowedDeliveryDates: [],
            };
        },
        methods: {
            ...mapActions('orderInformation', ['setDeliveryInformation']),

            async toBuyerInfoPage(deliveryData) {
                const result = await this.v$.$validate();
                if (!result) return;

                const data = this.prepareData(deliveryData);

                this.setDeliveryInformation(data);
                this.$router.push('buyer-info');
            },

            prepareData(deliveryData) {
                const data = {
                    extraInformation: deliveryData.extraInformation,
                    deliveryMethodId: deliveryData.deliveryMethodId,
                    deliveryData: {},
                };

                data.deliveryData = {
                    deliveryTimeId: deliveryData.deliveryTimeId,
                    endTime: deliveryData.endTime,
                    deliveryStartDate: deliveryData.deliveryStartDate,
                    startTime: deliveryData.startTime,
                };

                return data;
            },

            async getCalendarDays() {
                try {
                    const response = await CalendarDaysApi.getAvailableDays({ withoutFirstThreeDays: true });
                    this.allowedDeliveryDates = response.data.map((calendarDay) => calendarDay.date);
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },
        async mounted() {
            await this.getCalendarDays();
        },
    };
</script>

<style lang="scss" scoped>
    .delivery-wrapper {
        @include media($lg) {
            margin: 0 auto;
            max-width: 576px;
        }
    }

    .btn-section__individual {
        button {
            margin-bottom: 20px;
        }

        .arrow-icon {
            transform: rotate(90deg);
        }

        .footer_button {
            @include row-align-center-justify-end;

            border-top: solid $grey-line 1px;
            padding-top: 10px;
        }
    }
</style>
